import React from "react"

import Page from "../components/layout/Page"
import SEO from "../components/seo"

import PageHeader from "../components/layout/PageHeader"

const TermsPage = () => (
  <>
    <SEO page="terms" title="SignMonkey Terms of Service" />
    <Page
        breadcrumb={[
            {title: "Home", url:"/"},
            {title: "Terms Of Service", url:null}
        ]}
    >
      <PageHeader title="SignMonkey Terms Of Service" />
      <h3>Acceptance of Terms</h3>
      <p>
        Your purchase of SignMonkey.com products or services is subject to this Terms of Sale. By clicking the “add to cart” button and making a purchase, you acknowledge that you have read this document and agree to be bound by its terms.
      </p>
      <h3>Content of Your Purchase</h3>
      <p>
        The primary value of the SignMonkey.com Website (Web Site) is the ability for users and potential purchasers (“Users”) to create signs and graphics that display any content they choose. This is done by means of computer interaction alone between the Users and the Web Site.
      </p>
      <p>
        As such, you agree that no SignMonkey.com employee, employer, officer, owner, agent or authorized representative is responsible for monitoring the content of the signs or graphics created or uploaded by Users, neither while on the website, nor after purchase and during the production process.
      </p>
      <p>
        Due to the differences in computer equipment, software, web browsers, etc., SignMonkey can not be held responsible for pricing that is incorrect or outside the scope of production.  Occasionally sizes, prices, or product combinations may not be available. In this instance, SignMonkey will issue a full refund, in a timely manner.
      </p>
      <p>
        You agree that signs that are purchased will not be checked for content, spelling errors, grammatical errors, logical errors, aesthetic quality, color matching or any other factor that is within the User’s Realm of Control through the use of the tool on the Web Site. You, as a User, have many opportunities during the purchasing process to check your purchase for layout and content and agree that you have done so.
      </p>
      <p>
        “Realm of Control” refers to any part of a sign that is editable by the User. Including but not limited to: color, font size, font type, font style, font color, position of text, position of graphics, color of graphics, quantity of text, quantity of graphics, spelling of text, use of text, size of graphics, justification of graphics, justification of images, or color of the background.
      </p>
      <p>
        While SignMonkey.com does not review the content of your sign or product, you still agree to be bound by the restrictions as laid out in the Terms of Service, and should it come to the attention of SignMonkey.com that you have violated the Terms of Service or this Terms of Sale, you agree that SignMonkey.com will send you notice of cancellation and your order will be canceled. You further agree that the purchase price of your order, less any cost incurred during the processing of your order and its cancellation, will be returned by signed check through the United States Postal Service, postmarked within 30 days of your receipt of notice of cancellation.
      </p>
      <p>
        You also agree that the content of your sign or product does not contain any content that is unlawful, harmful, threatening, abusive, vulgar, harassing, defamatory, obscene, pornographic, indecent, inflammatory, libelous, tortious, hateful, or racially, ethnically or otherwise objectionable, or invasive of another’s rights including but not limited to rights of celebrity, privacy and intellectual property.
      </p>
      <p>
        SignMonkey.com sells its products through an E-Commerce Web Site viewable by computers connected to the Internet. These computers may view SignMonkey.com products solely through their computer screens (“Monitors”). Monitors may be configured to display images and colors in a variety of ways, both through the use of hardware setup and software setup. There are many possible settings for Monitors and there is no true uniform standard to which all Monitors conform. What may look one way on one monitor, will look different on another.
      </p>
      <p>
        Furthermore, computer monitors display images with light and use a standard known as RGB to do so. SignMonkey.com produces products in a variety of ways (CMYK, PMS, and others) that do not use light.
      </p>
      <p>
        As such, you agree that SignMonkey.com does not guarantee that the colors, as they appear on the screen, will look the same as the colors as they appear on the purchased product. SignMonkey.com works hard to make the colors on the screen be as true as possible to the printed colors, however, the differences in Monitors and the differences between light and pigment make it impossible to guarantee matching colors for all Users.
      </p>
      
      <h3>Uploaded Images</h3>
      <p>
        SignMonkey.com permits users to upload images onto their signs and products through the SignMonkey.com Web Site. You agree that SignMonkey.com is not responsible for the quality of the images you upload. The SignMonkey.com Web Site attempts to make a “best guess” as to the best viewing distance of your image based on the internally stored DPI (Dots Per Inch) contained in the image file. The Web Site assumes that 100 DPI is best viewed from 3.5 feet and tries to make a best distance estimate based on that assumption.
      </p>
      <p>
        You agree that you alone are responsible for making sure that the quality of the image you are uploading is of high enough quality to be satisfactory to you at the size you select on the sign or product that you are purchasing.
      </p>
      <p>
        Images are created and stored in a variety of ways and you agree that SignMonkey.com is not responsible for ensuring quality images for any image file format uploaded to the Web Site.
      </p>
      
      <h3>Designs</h3>
      <p>
        SignMonkey.com offers a broad range of designs available on the Web Site for use with your signs and products. These designs were created by SignMonkey.com and are the sole property of SignMonkey.com. Designs are not to be copied or reproduced without written permission from SignMonkey.com. Designs created using SignMonkey.com cannot be copyrighted by the enduser and are the sole property of SignMonkey.com. You agree that this clip art is for novelty or advertising purposes only and are not meant to be taken as official symbols, art or graphics. You also agree that these designs have not been approved through official organizations or groups.
      </p>
      
      <h3>Alternate Payment Methods</h3>
      <p>
        SignMonkey.com will accept alternate payment methods, if the method for payment is not included in the checkout, please contact us for payment instruction. No goods will begin production or shipped without full 100% payment. this includes cheques clearing bank.
      </p>
      
      <h3>Local Pickup</h3>
      <p>
        SignMonkey.com allows local pick up at no charge. Customer is responsible for loading and securing the sign on or in a capable vehicle. when the sign leaves our facility, any damage due to shipping or strapping will be the responsibility of the purchaser. Hauling channel letter signs on a open vehicle or trailer can cause damage due to a wind hitting side of letters and raceway. It is recommended that signs be hauled inside of container.
      </p>
      
      <h3>Wrong Shipping Address</h3>
      <p>
        Signs will be shipped to the address supplied by the purchaser in the checkout. If this address is incorrect and the sign is shipped to the wrong address. The purchaser will be responsible for any additional shipping cost. The shipping time will also increase and is not the fault of SignMonkey.com.
      </p>
      
      <h3>Production Time</h3>
      <p>
        SignMonkey.com works hard to ensure that orders will be produced and exit the SignMonkey.com facility within three business days from the date of order. The date of order is the calendar date of order if the order is placed before 12:00pm Easter Time and the next business day after the calendar date of order if the order is placed between 6:00pm and 11:59pm Eastern Time.
      </p>
      <p>
        This means that a purchase made at 3:00pm Monday, will be shipped out on Thursday of the same week. This production time is not guaranteed and is limited in many ways as discussed in the Shipping Policy.
      </p>
      
      <h3>Return and Refund Policy</h3>
      <p>
        Signmonkey.com Products are built to order and therefore not returnable. By purchasing signmonkey.com products you agree that, SignMonkey.com guarantees the products it sells are free from manufacturing defects and as such will be good for 3 years after purchase of the product. If the product fails or breaks due to a manufacturing defect during that time, you agree to take a photograph of the failed or broken product and send the photograph or the original failed or broken product to SignMonkey.com. Upon receipt of the product or the photograph, SignMonkey.com will provide repair, repair parts or replace of the product at SignMonkey.com’s discretion.
      </p>
      <p>
        You further agree to provide full and complete information regarding the circumstances of the failure or breakage, including but not limited to the use of the product, the conditions the product was in, and any other information useful to an informed determination by SignMonkey.com as to the cause of the failure or breakage.
      </p>

      
      <h3>Changes to Your Order</h3>
      <p>
        SignMonkey.com provides many opportunities to review, revise, and cancel the order process before actually placing your order. SignMonkey.com is able to offer its products at the present prices due to the efficiency of allowing the customer to create and modify their own products.
      </p>
      <p>
        You agree that SignMonkey.com is not responsible for reviewing, revising or canceling orders, products or images once an order has been placed. A SignMonkey.com employee, employer, officer, owner or authorized agent may revise or cancel an order but is not required or obligated to do so.
      </p>
      <p>
        You agree that you have reviewed your order and that no further additions, corrections or changes need to be made and that your order is final as is.
      </p>
    </Page>
  </>
)

export default TermsPage